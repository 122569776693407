import {
  APP_INSTALLED_ACTION,
  APP_REMOVED_ACTION,
  BLOCKS_PRODUCT_PAGE_APP_DEF_ID,
  BLOCKS_PRODUCT_PAGE_WIDGET_ID,
  ECOM_APP_DEF_ID,
} from './constants';
import {PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {EcomComponent, withEcomPlatform} from '@wix/ecom-platform-sdk/dist/es/src';
import {doTransaction} from '../editor-script/transaction';
import {SPECS} from '../editor-script/constants';
import {ExperimentsApi} from '../common/experiments/ExperimentsApi';

const ecomComponents = [
  EcomComponent.CART_ICON,
  EcomComponent.CART,
  EcomComponent.CHECKOUT,
  EcomComponent.THANK_YOU_PAGE,
];
const ecomComponentsWithoutCart = [EcomComponent.CART_ICON, EcomComponent.CHECKOUT, EcomComponent.THANK_YOU_PAGE];
let sdk: IEditorSdk;
let experimentsApi: ExperimentsApi;

async function getEcomPagesData() {
  const ecomApplicationId = (await sdk.tpa.app.getDataByAppDefId('', ECOM_APP_DEF_ID)).applicationId;
  const allSitePages = await sdk.pages.data.getAll('');
  return allSitePages.filter((page) => page.tpaApplicationId === ecomApplicationId);
}

async function checkIfPageExists(page: PageMap) {
  const ecomPagesData = await getEcomPagesData();
  return !!ecomPagesData.find((p) => p.tpaPageId === page);
}

function checkIfBlocksProductPageExists(): Promise<boolean> {
  return sdk.document.tpa.isApplicationInstalled('', {
    appDefinitionId: BLOCKS_PRODUCT_PAGE_APP_DEF_ID,
  });
}

function installBlocksProductPage(): Promise<void> {
  return sdk.document.tpa.add.application('', {
    appDefinitionId: BLOCKS_PRODUCT_PAGE_APP_DEF_ID,
  });
}

function addStoresPages() {
  return doTransaction(
    sdk,
    async () => {
      if (
        (experimentsApi.enabled(SPECS.InstallBlocksProductPage) && (await checkIfBlocksProductPageExists())) ||
        (!experimentsApi.enabled(SPECS.InstallBlocksProductPage) && (await checkIfPageExists(PageMap.PRODUCT)))
      ) {
        return Promise.resolve();
      }

      const ecomPublicApi = sdk.application.getPublicAPI('', {appDefinitionId: ECOM_APP_DEF_ID});
      const productPagePromise = experimentsApi.enabled(SPECS.InstallBlocksProductPage)
        ? installBlocksProductPage()
        : ecomPublicApi.addPage(PageMap.PRODUCT);
      const shopPagePromise = ecomPublicApi.addPage(PageMap.GALLERY, true);

      return Promise.all([shopPagePromise, productPagePromise]);
    },
    experimentsApi.experiments
  );
}

async function editorReady(editorSDK, _appDefId, options) {
  sdk = editorSDK;

  const instance: string = await (sdk as any).document.info.getAppInstance('');
  experimentsApi = new ExperimentsApi(instance);
  await experimentsApi.fetch(false);

  const isEcomPlatformInstalled = await sdk.document.tpa.isApplicationInstalled('', {
    appDefinitionId: ECOM_APP_DEF_ID,
  });

  if (options.firstInstall && isEcomPlatformInstalled) {
    await addStoresPages();
  }
}

async function handleAction({type, payload}) {
  if (type === APP_INSTALLED_ACTION && payload?.appDefinitionId === ECOM_APP_DEF_ID) {
    await addStoresPages();
  }

  if (type === APP_REMOVED_ACTION && payload?.appDefinitionId === ECOM_APP_DEF_ID) {
    await sdk.tpa.app.delete();
    await sdk.document.save();
  }

  if (
    experimentsApi.enabled(SPECS.InstallBlocksProductPage) &&
    type === APP_INSTALLED_ACTION &&
    payload?.appDefinitionId === BLOCKS_PRODUCT_PAGE_APP_DEF_ID
  ) {
    const page = await sdk.pages.add('', {
      title: 'Product Page',
      definition: {
        data: {
          managingAppDefId: ECOM_APP_DEF_ID,
        },
      },
    });

    await sdk.document.application.appStudioWidgets.addWidget('', {
      containerRef: page,
      widgetId: BLOCKS_PRODUCT_PAGE_WIDGET_ID,
      layout: {
        width: 100,
        height: 100,
        x: 0,
        y: 0,
      },
    });
  }
}

const editorScript = {
  editorReady,
  handleAction,
};

export const storesEditorScript = withEcomPlatform(editorScript, ecomComponents);

export const storesEditorScriptWithoutCart = withEcomPlatform(editorScript, ecomComponentsWithoutCart);
